import { AbTest, Lts } from "@product/rosetta-sdk";
import { theme, useResponsive } from "@product/scmp-sdk";
import { useEffect, useMemo } from "react";

import { ExperimentAPINames, useActiveExperiment } from "scmp-app/lib/optimizely";

export const usePaywallLtsOfferABTest = () => {
  const testName = Lts.LTS_AB_TEST_NAME;
  const { experiment } = useActiveExperiment(testName);

  useEffect(() => {
    if (!experiment) return;

    AbTest.setABTestVariablePianoCustomVariable({
      experimentId: experiment.id,
      testName,
      variant: experiment.variation,
    });
  }, [experiment, testName]);
};

export const usePaywallMaskingABTest = () => {
  const { experiment } = useActiveExperiment(ExperimentAPINames.PaywallMasking);
  const isMobileOrTablet = useResponsive(theme.breakpoints.down("desktop"), false);
  const isHigherPaywallAtFirstParagraphABTest = useMemo(
    () => experiment?.variation?.name === "1",
    [experiment],
  );
  const isLowerPaywallABTest = useMemo(() => experiment?.variation?.name === "2", [experiment]);

  const getNthParagraphIndex = (position: number, schema: Schema) => {
    let count = 0;
    for (const [index, element] of schema.entries()) {
      if (count === position) {
        return index;
      }
      if (element.type === "p") {
        count++;
      }
    }
  };

  const getPaywallPosition = (schema: Schema) => {
    const isVeryShortArticle = schema.filter(node => node.type === "p").length < 10;

    if (isVeryShortArticle) return null;
    if (isHigherPaywallAtFirstParagraphABTest) return getNthParagraphIndex(1, schema);
    if (isLowerPaywallABTest)
      return isMobileOrTablet ? getNthParagraphIndex(7, schema) : getNthParagraphIndex(6, schema);
    return null;
  };

  return {
    getPaywallPosition,
  };
};
