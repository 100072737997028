import type { FunctionComponent, ReactNode } from "react";
import { createContext, useCallback, useContext, useMemo, useState } from "react";

export type ArticlePianoStates =
  | "paywallNotRequired"
  | "requiresInlinePaywall"
  | "requiresModalPaywall"
  | "shownInlinePaywall"
  | "shownModalPaywall";

type ArticlePianoStateMap = Record<string, ArticlePianoStates>;

type ContextValue = {
  articlePianoPaywallStateMap?: ArticlePianoStateMap;
  resetArticlePianoPaywallStateMap?: () => void;
  updateArticlePianoPaywallStateState?: (parameters: updatePianoPaywallStateMapParameters) => void;
};

type updatePianoPaywallStateMapParameters = {
  articleEntityId: string;
  state: ArticlePianoStates;
};

const ArticlePianoPaywallContext = createContext<ContextValue>({});

export type Props = {
  children?: ReactNode;
};

export const ArticlePianoPaywallProvider: FunctionComponent<Props> = ({ children }) => {
  const [articlePianoPaywallStateMap, setArticlePianoPaywallStateMap] =
    useState<ArticlePianoStateMap>({});

  const clonedArticlePianoPaywallStateMap = useMemo(
    () => ({
      ...articlePianoPaywallStateMap,
    }),
    [articlePianoPaywallStateMap],
  );

  const updateArticlePianoPaywallStateState = useCallback(
    (parameters: updatePianoPaywallStateMapParameters) => {
      setArticlePianoPaywallStateMap?.(stateMap => ({
        ...stateMap,
        [parameters.articleEntityId]: parameters.state,
      }));
    },
    [setArticlePianoPaywallStateMap],
  );

  const resetArticlePianoPaywallStateMap = () => {
    setArticlePianoPaywallStateMap?.(() => ({}));
  };

  return (
    <ArticlePianoPaywallContext.Provider
      value={{
        articlePianoPaywallStateMap: clonedArticlePianoPaywallStateMap,
        resetArticlePianoPaywallStateMap,
        updateArticlePianoPaywallStateState,
      }}
    >
      {children}
    </ArticlePianoPaywallContext.Provider>
  );
};
ArticlePianoPaywallProvider.displayName = "ArticlePianoPaywallProvider";

export const useArticlePianoPaywallContext = () => useContext(ArticlePianoPaywallContext);
