// cspell:ignore Winback
import type { Fact, MeterData } from "@product/rosetta-sdk";
import rosettaSdk from "@product/rosetta-sdk";
import { safeSetLocalStorage } from "@product/scmp-sdk";
import { useAtomValue } from "jotai";
import capitalize from "lodash/capitalize";
import { useCallback } from "react";

import { getGoogleLoginUrl } from "shared/lib/account";
import { accountAtom } from "shared/lib/account/atoms";

import { useArticlePianoPaywallContext } from "scmp-app/components/article/article-piano-paywall/contexts";
import { useLoginDialogStateHelper } from "scmp-app/components/login-dialog/hooks";
import {
  sendGA4Tracking,
  sendGA4UserPropertiesTracking,
} from "scmp-app/components/tracking/google-analytics-4/apis";
import { sendGTMSubscribeVariables } from "scmp-app/components/tracking/google-tag-manager/apis";
import type { UntypedGA4Event } from "scmp-app/components/tracking/google-tag-manager/types";
import { PaywallMeterModalClassName } from "scmp-app/lib/rosetta/consts";
import { eventEmitter } from "scmp-app/lib/rosetta/event-emitter";
import {
  MaxReadableArticleCountPerLoggedInUser,
  PaywallMeterViewsLeftLocalstorageKey,
  PaywallMeterViewsLocalstorageKey,
  RegiWallLoginMethodLocalstorageKey,
} from "scmp-app/lib/rosetta/hooks/rosetta-event-handlers/consts";
import { useRosettaSetState } from "scmp-app/lib/rosetta/hooks/rosetta-set-state";

export const useRosettaEventHandlers = () => {
  const { openLoginDialog } = useLoginDialogStateHelper();
  const { handleUpdateRosettaState } = useRosettaSetState();
  const { isLoggedIn } = useAtomValue(accountAtom);
  const { updateArticlePianoPaywallStateState } = useArticlePianoPaywallContext();

  const attachEventHandlers = useCallback(() => {
    // TODO: add the event handlers after https://scmp-product-tech.atlassian.net/browse/SCMPWEB-2651 clean up the code

    const handleUpdateRosettaMeterData = (meterData: MeterData) => {
      const { maxViews, views, viewsLeft } = meterData;
      // cspell: ignore regi
      // ignore max = 1 (regi wall) and max = 50 (ad block)
      if ([1, 50].includes(maxViews)) return;

      handleUpdateRosettaState({ meterData });
      safeSetLocalStorage(PaywallMeterViewsLocalstorageKey, `${views}`);
      safeSetLocalStorage(PaywallMeterViewsLeftLocalstorageKey, `${viewsLeft}`);
    };

    rosettaSdk.on("experienceExecute", async context => {
      const handleIPWhitelistGTMVariables = () => {
        const isIPWhiteListCases =
          window.tp?.customVariables?.ip_access_user &&
          window.tp?.customVariables?.ip_access_client;
        if (!isIPWhiteListCases) return;

        sendGTMSubscribeVariables({
          ipClientName: window.tp?.customVariables?.ip_access_client,
        });

        sendGA4UserPropertiesTracking({
          product: "SCMP - IP whitelisting",
        });

        if (window.tp?.customVariables?.ip_ezproxy_user)
          sendGA4UserPropertiesTracking({ ipClientIsEZproxy: "EZproxy" });
        else sendGA4UserPropertiesTracking({ ipClientIsEZproxy: "IP whitelisting" });
      };

      handleIPWhitelistGTMVariables();

      let paywallState = "paywallNotRequired";
      const requiresInlinePaywall = context.result?.events?.find?.(
        event => event?.eventParams?.displayMode === "inline",
      );
      if (requiresInlinePaywall) paywallState = "requiresInlinePaywall";

      const requiresModalPaywall = context.result?.events?.find?.(
        event => event?.eventParams?.displayMode === "modal",
      );
      if (requiresModalPaywall) paywallState = "requiresModalPaywall";

      const hasIpAccess = computeHasIpAccess();
      rosettaSdk.rulesEngine.addFact("hasIpAccess", computeHasIpAccess());
      rosettaSdk.rulesEngine.setReady("pianoExperience");
      await rosettaSdk.rulesEngine.run();

      const hasScmpPlusAccessRight = rosettaSdk?.rulesEngine.getOutcome(
        "user.hasSCMPPlusAccessRight",
      );
      const hasPostiesAccessRight = rosettaSdk?.rulesEngine.getOutcome(
        "user.hasPostiesAccessRight",
      );
      handleUpdateRosettaState({
        hasIpAccess,
        hasPianoExperienceExecuted: true,
        hasPostiesAccessRight,
        hasScmpPlusAccessRight,
      });
      eventEmitter.emit("showPaywall", paywallState);

      // The window.tp variable is populated after running rosettaSdk.execute
      function computeHasIpAccess() {
        return (
          (typeof window !== "undefined" && window?.tp?.customVariables?.ip_access_user) ?? false
        );
      }

      // GA4 user properties
      const { ipAccess, siteLicenseContractIds } =
        rosettaSdk.rulesEngine.getOutcome("tracking.ga4");
      if (siteLicenseContractIds) {
        sendGA4UserPropertiesTracking({ ga4SiteLicensing: siteLicenseContractIds });
      }

      const ipAccessClientId = ipAccess?.id;
      const ipAccessClientType = ipAccess?.type;
      if (ipAccessClientId && ipAccessClientType) {
        switch (ipAccessClientType) {
          case "ezproxy":
            sendGA4UserPropertiesTracking({ ga4Ezproxy: ipAccessClientId });
            break;
          case "whitelisting":
            sendGA4UserPropertiesTracking({ ga4IpWhitelisting: ipAccessClientId });
            break;
        }
      }
    });

    rosettaSdk.on("showOffer", offerParameters => {
      const handleModalOffer = () => {
        if (offerParameters.displayMode !== "modal") return;
        document.body.classList.add(PaywallMeterModalClassName);
      };

      // TODO: handle `closePianoMeter()` if displayMode === modal, code:elBody.classList.add('offer-meter-open')
      const handleHardPaywallTrackingAndUpdateData = () => {
        if (!offerParameters.activeMeters) return;
        const activeMeters = JSON.parse(offerParameters.activeMeters) as MeterData[];
        if (!activeMeters || activeMeters.length === 0) return;

        const [meterData] = activeMeters; // Assume it has one meter
        const { maxViews, totalViews, viewsLeft } = meterData;
        const isHardPaywall =
          totalViews > MaxReadableArticleCountPerLoggedInUser &&
          maxViews === MaxReadableArticleCountPerLoggedInUser &&
          viewsLeft === 0;

        if (!isHardPaywall) return;

        handleUpdateRosettaMeterData(meterData);

        if (isLoggedIn) {
          rosettaSdk.getService("cartAbandoner")?.showCartAbandonerOfferOnNextVisit();
        }
      };

      handleModalOffer();
      handleHardPaywallTrackingAndUpdateData();
    });

    rosettaSdk.on("meterActive", meterData => {
      handleUpdateRosettaMeterData(meterData);
    });

    rosettaSdk.registerListener("pwa/request-from-template");
    rosettaSdk.on("COMM_CHANNEL", parameters => {
      // TODO: refactor those events which is calling rosettaSDK directly, no point to round trip
      const { message } = parameters;
      if (!message?.requestType) return;
      switch (message.requestType) {
        case "action/showPaywall":
          eventEmitter.emit("showPaywall", "requiresInlinePaywall");
          message.responseData = "ready";
          break;
        case "action/trackGA4":
          // Skip the type checking at here
          sendGA4Tracking(message.requestData as unknown as UntypedGA4Event, { untyped: true });
          break;
        case "appConfig/rosetta":
        case "context/appConfig":
          message.responseData = rosettaSdk.getRosettaAppConfig();
          break;
        case "cartAbandoner": {
          const svc = rosettaSdk.getService("cartAbandoner");
          switch (message.requestData?.action) {
            case "cartAbandonerOfferShown":
              svc?.cartAbandonerOfferShown();
              break;
            case "clearCookies":
              svc?.cleanupCookies();
              break;
            case "login":
              openLoginDialog({
                destination: window.location.origin + window.location.pathname,
                ga4CustomParameter: {
                  trigger_point: "abandoned cart popup",
                },
              });
              break;
            case "registerOfferShown":
              svc?.registerOfferShown();
              break;
          }
          return;
        }
        case "context/subscriberPhase":
          message.responseData = rosettaSdk.rulesEngine.getOutcome("tracking.ga4.subscriberPhase");
          break;
        // TODO: seems those state/* not in use, so will skip it for now, will skip optimize related code as well
        case "context/subscriptionStatus":
          message.responseData = rosettaSdk.rulesEngine.getOutcome("user");
          break;
        case "localStorage/get":
          const localStorageGetVariable = message.requestData?.localStorage;
          if (localStorageGetVariable?.includes("rSDK_")) {
            const localStorageResult = localStorage.getItem(localStorageGetVariable);
            message.responseData = {
              localStorageResult,
              localStorageVar: localStorageGetVariable,
            };
          }
          break;
        case "localStorage/set":
          const localStorageSetVariable = message.requestData?.localStorage;
          const localStorageValue = message.requestData?.localStorageVal;
          if (localStorageSetVariable?.includes("rSDK_") && localStorageValue) {
            safeSetLocalStorage(localStorageSetVariable, JSON.stringify(localStorageValue));
            message.responseData = {
              localStorageResult: localStorageValue,
              localStorageVar: localStorageSetVariable,
            };
          }
          break;
        case "login/archive":
          openLoginDialog({
            destination: window.location.origin + window.location.pathname,
            ga4CustomParameter: {
              paywall_type: "archive_wall",
              trigger_point: "paywall",
            },
            wallType: "archive",
          });
          break;
        case "login/freeTrialPromo":
          openLoginDialog({
            destination: window.location.origin + window.location.pathname,
            ga4CustomParameter: {
              trigger_point: "paywall",
            },
            wallType: "freeTrialPromo",
          });
          break;
        case "login/google/regiwall":
          safeSetLocalStorage(RegiWallLoginMethodLocalstorageKey, "Google");
          location.href = getGoogleLoginUrl({
            registerSource: "loginwall",
          });
          break;
        case "login/meter2":
          openLoginDialog({
            destination: window.location.origin + window.location.pathname,
            ga4CustomParameter: {
              paywall_type: "meter_wall_2",
              trigger_point: "paywall",
            },
            wallType: "meter2",
          });
          break;
        case "login/meter4":
          openLoginDialog({
            destination: window.location.origin + window.location.pathname,
            ga4CustomParameter: {
              paywall_type: "meter_wall_4",
              trigger_point: "paywall",
            },
            wallType: "meter4",
          });
          break;
        case "login/newsletter":
          openLoginDialog({
            destination: window.location.origin + window.location.pathname,
            ga4CustomParameter: {
              paywall_type: "newsletter_wall",
              trigger_point: "paywall",
            },
            wallType: "newsletter",
          });
          break;
        case "login/paywall":
          openLoginDialog({
            destination: window.location.origin + window.location.pathname,
            ga4CustomParameter: {
              paywall_type: "paywall",
              trigger_point: "paywall",
            },
            wallType: "paywall",
          });
          break;
        case "login/regiwall":
          openLoginDialog({
            description: "To continue reading, create your free account or log in now",
            destination: window.location.origin + window.location.pathname,
            ga4CustomParameter: {
              paywall_type: "sign in wall",
              trigger_point: "paywall",
            },
            wallType: "regi",
          });
          break;
        case "login/velocity":
          openLoginDialog({
            destination: window.location.origin + window.location.pathname,
            ga4CustomParameter: {
              paywall_type: "velocity_wall",
              trigger_point: "paywall",
            },
            wallType: "velocity",
          });
          break;
        case "rendered/paywall":
          const articleEntityId = message.requestData.articleEntityId;
          const paywallType = message.requestData.paywallType;
          if (articleEntityId) {
            updateArticlePianoPaywallStateState?.({
              articleEntityId,
              state: `shown${capitalize(paywallType)}Paywall`,
            });
          }
          break;
        case "rule/facts":
          message.responseData =
            message.requestData.name === "*"
              ? rosettaSdk.rulesEngine.getAllFacts()
              : rosettaSdk.rulesEngine.getFact(message.requestData.name as keyof Fact);
          break;
        case "rule/outcome":
          message.responseData =
            message.requestData.name === "*"
              ? rosettaSdk.rulesEngine.getAllOutcome()
              : rosettaSdk.rulesEngine.getOutcome(message.requestData.name);
          break;
        case "tp/customVariables":
          message.responseData = window.tp?.customVariables ?? {};
          break;
        default:
          message.error = true;
          message.errorMessage = "unknown requestType";
          break;
      }
      const { channel: _channel, ...messageWithoutChannel } = message;
      rosettaSdk.sendMessage("pwa/response-from-app", {
        ...parameters,
        message: messageWithoutChannel,
      });
    });
  }, [handleUpdateRosettaState, isLoggedIn, openLoginDialog, updateArticlePianoPaywallStateState]);

  return { attachEventHandlers };
};
